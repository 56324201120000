import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { h, render } from 'preact';
import { useContext } from 'preact/hooks';
import { GlobalContext } from '../GlobalState';
import { verifyInfoFormSchema } from '../validators/contactForm.validator';
import { leadPost } from '../utility/leadPost';
import { showSpinner, closeSpinner, showConfirmationModal } from '../utility/leadPost/showSpinner.utility';

// TODO: post data to pingPixel, get response, ping mortgage endpoint, check for LeadId GUID

export const SubmitButton = (props, ref) => {
  const { state, updateModelProperty } = useContext(GlobalContext);
  const { firstName, lastName, email, phone, address, zip } = props
  const onClickHandler = (ev) => {
    ev.preventDefault();

    const isValidInput = async (schema) => {
      try {
        const pixelHtml = '<iframe src="https://injurymaxpay.com/e/?ev=lead" width="1" height="1" style="visibility: hidden; display: none;"></iframe>';
        const valid = await schema.validateSync(
          {
            ...props
          },
          { abortEarly: false }
        );

        const isFiltered = (state.retainedAttorney === 'Yes') || (state.injured === 'No') || (state.atFault === 'Yes');

        if (valid) {
          updateModelProperty({
            firstName,
            lastName,
            phone,
            address,
            zip,
            email
          });

          // check for retainedAttorney = 'Yes' or injured = 'No' or atFault = 'Yes', if so filter the pixel
          if (!isFiltered) {
            document.querySelector('body').insertAdjacentHTML('beforeend', pixelHtml);
          }

          // POST THE LEAD
          leadPost({
            ...state,
            firstName,
            lastName,
            phone,
            address,
            zip,
            email
          });

          showSpinner();
          setTimeout(closeSpinner, 300);

          // TODO: determine time and show the appropriate messaging.
          showConfirmationModal(firstName);
        }
      } catch (error) {
        // Debugging
        // console.log(Array.isArray(error.inner), '\n\n\nError Inner:', error.inner);

        // DISPLAY ERROR MESSAGES

        // using path, update add the error classes
        error.inner.forEach(err => {
          const errorFieldId = err.path;
          const errorField = document.getElementById(errorFieldId);
          const errorMessage = document.querySelector('#' + errorFieldId + ' ~ .invalid-feedback');

          // Debugging
          // console.log(err.path, err.message);
          // console.log(errorField, errorMessage, errorFieldId + ' ~ .invalid-feedback');

          errorField.classList.add('is-invalid');

          if (!errorMessage.innerHTML.length) {
            errorMessage.insertAdjacentHTML('beforeend', err.message)
          }
        });
      }
    };

    isValidInput(verifyInfoFormSchema);
  }

  return (
    <div className="next-btn-wrap col-12 text-center mb-4">
      <button {...props}
              onClick={onClickHandler}
              className={'flow-btn btn btn-outline-dark btn-default btn-lg'}>Get My Evaluation
      </button>
    </div>
  );
}
