export function formObserver() {
  const formElem = document.querySelector('form#accident-type');
  const observer = new MutationObserver(observerCallback);
  const observerConfig = {
    childList: true
  };

  observer.observe(formElem, observerConfig);
}

function observerCallback(mutationList) {
  mutationList.forEach(mutation => {
    mutation.addedNodes.forEach(node => {
      let sub3Input = '';

      if (node.id === 'sub3') {
        sub3Input = document.getElementById(node.id).value;
        document.cookie = `sub3=${sub3Input}; domain=.injurymaxpay.com`;
      }
    });
  })
}
