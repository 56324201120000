export const initFormData = {
  GUID: '',
  click_id: '',
  email: '',
  inParams: '',
  kw: '',
  mediacid: '',
  mediatag: '',
  offer: '',
  phone: '',
  r: '',
  s1: '',
  s2: '',
  s3: '',
  s4: '',
  s5: '',
  sub_id: '',
  tpl: ''
};
